div.vto-skin-care-formatter {
  .sd-product-grid {
    .elc-grid-item-product {
      .elc-product {
        &-sub-display-name,
        &-short-description-wrapper {
          margin-#{$ldirection}: 2.5%;
        }
      }
    }
  }
}
