ul.footer-social-links {
  width: 96px;
  li a.line {
    background-image: url('/media/images/Footer/line_logo.png') !important;
    background-repeat: no-repeat;
    height: 24px;
    margin: 0 0 0 19px;
    width: 24px;
    &:hover {
      background-position: -24px 0;
    }
  }
  .footer-forms-location-sms-email-signup-elc-nodeblock {
    .footer-find-us {
      display: block;
      text-align: center;
      margin: 0 auto;
    }
  }
}
