/* Facebook Button Alignment Styles */
.social-login {
  &__container {
    padding: 20px 25px;
    @media #{$medium-up} {
      padding: 20px 0;
    }
    .social-login__divider {
      width: 100%;
    }
    .social-login__email-opt-in {
      text-align: justify;
      margin-top: 20px;
    }
    .social-login__terms {
      text-align: $ldirection;
      padding: 0;
    }
  }
}
/* G-nav Styles */
#cboxLoadedContent {
  .sign-in-component {
    .social-login {
      padding: 20px 30px;
      margin-#{$rdirection}: 0;
      @media #{$medium-up} {
        padding: 0;
      }
      &__container {
        text-align: center;
        display: block !important;
        .social-login__divider {
          width: 100%;
        }
      }
    }
  }
}

.signin-overlay-wrapper {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        max-height: 550px;
      }
    }
  }
}
/* Account Profile Page */
.social-info {
  padding-top: 0;
  border-top: none;
  padding-#{$ldirection}: 19px;
  @media #{$medium-up} {
    padding-top: 7%;
  }
  @media #{$large-up} {
    margin-#{$ldirection}: 25%;
  }
  @media #{$xlarge-up} {
    margin-#{$ldirection}: 20%;
  }
}
/* Account Index Page */
#registration {
  .social-info-block {
    @media #{$large-up} {
      position: static;
      margin-top: 0;
    }
    .social-info {
      @media #{$medium-up} {
        margin-#{$ldirection}: 25%;
      }
      @media #{$large-up} {
        padding-top: 0;
        margin-#{$ldirection}: 20%;
      }
      &__connect {
        display: flex;
      }
    }
  }
}
/* Account Signin Page */
#signin {
  .sign-in-page {
    .social-login {
      &__email-opt-in,
      &__terms {
        @media #{$medium-up} {
          margin-#{$ldirection}: 15px;
        }
        @media #{$large-up} {
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
}
/* Checkout Page */
.checkout--single-page {
  .social-login {
    &__container {
      padding: 20px 0;
    }
  }
}
/* Appointment booking page styling */
.appointments-container {
  .social-login {
    &__container {
      padding: 20px 0;
    }
  }
}
/* Order confirmation page styling */
#confirm {
  .pg_wrapper {
    .social-login {
      padding: 0;
      margin-top: 30px;
      @media #{$medium-up} {
        margin-top: 0;
      }
      &__container {
        border-top: 1px solid $color-light-gray;
      }
      &__info {
        display: block;
      }
    }
  }
}
