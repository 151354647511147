.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_MOBILE_Chinese.jpg');
}

#appointment-book-container {
  .artist {
    display: none;
  }
  .appointments-container {
    #booking-step1 {
      .artist-select-container {
        display: none !important;
      }
    }
  }
  .location-select-section {
    h2 {
      &.location-select-section-content {
        &__title {
          font-size: 45px;
        }
      }
    }
  }
  .appt-book-location-selector {
    margin-top: 70%;
  }
  .services-container {
    .service {
      height: auto !important;
    }
    .service-head {
      .icon {
        display: none;
      }
    }
  }
  .services-renutrive {
    .service {
      .service-title {
        width: 100%;
        text-align: center;
      }
      .service-content-container {
        padding: 0px;
        .service-image {
          display: block;
        }
        .service-details {
          width: 50%;
          .lesson_description {
            margin-left: 4.5%;
            margin-top: 10%;
          }
        }
        .selection-bar {
          width: 50%;
          .selection-selector {
            width: 100%;
          }
        }
      }
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .confirm-container {
        .total-time {
          width: 100%;
        }
        .appt-book-book-submit {
          width: 100%;
        }
        width: 80%;
        margin: auto;
      }
    }
  }
}

#popover_container {
  #hppopup {
    img {
      width: 100%;
    }
  }
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      width: 475px;
      text-align: center;
    }
  }
}

#cboxWrapper {
  #cboxContent {
    #cboxLoadedContent {
      width: 100% !important;
      height: 100%;
    }
  }
}

#viewcart {
  #cboxOverlay {
    background: none;
  }
  .page-wrapper {
    .messages {
      .error {
        color: $color-periwinkle-blue;
      }
    }
  }
}

#shipping {
  #main.multi {
    #address_form_container {
      fieldset.fs {
        div.form_element {
          float: left;
          margin: 5px 0px 0px 0px;
          padding: 0;
          width: 100%;
          input,
          select {
            margin-top: 0px !important;
            margin-bottom: 0px;
          }
          label {
            margin-left: 20px;
          }
          span.required_mark {
            margin-left: 10px;
          }
          label,
          span.required_mark {
            position: absolute;
            display: block;
            background-color: white;
            padding: 0px 4px;
            font-size: 15px;
            color: #aaa;
            margin-top: calc(0.7em - 1px);
            -webkit-transition: margin 0.5s, font-size 0.5s;
            transition: margin 0.5s, font-size 0.5s;
            width: auto;
          }
        }
        div.form_element.half,
        div.form_element.halfright {
          width: 49%;
        }
        div.form_element.halfright {
          float: right;
        }
        div.form_element.labelmode {
          label,
          span.required_mark {
            font-size: 10px;
            line-height: 9px;
            color: #aaa;
            margin-top: calc(-0.2em - 1px);
          }
        }
      }
    }
    #billing_address_form_container {
      fieldset.fsb {
        div.form_element {
          float: left;
          margin: 5px 0px 0px 0px;
          padding: 0;
          width: 100%;
          padding-bottom: 0;
          input,
          select {
            margin-top: 0px !important;
            margin-bottom: 0px;
          }
          label {
            margin-left: 20px;
          }
          span.required_mark {
            margin-left: 10px;
          }
          label,
          span.required_mark {
            position: absolute;
            display: block;
            background-color: white;
            padding: 0px 4px;
            font-size: 15px;
            color: #aaa;
            margin-top: calc(0.7em - 1px);
            -webkit-transition: margin 0.5s, font-size 0.5s;
            transition: margin 0.5s, font-size 0.5s;
          }
        }
        div.form_element.half,
        div.form_element.halfright {
          width: 49%;
        }
        div.form_element.halfright {
          float: right;
        }
        div.form_element.labelmode {
          label,
          span.required_mark {
            font-size: 10px;
            line-height: 9px;
            color: #aaa;
            margin-top: calc(-0.2em - 1px);
          }
        }
      }
    }
  }
}

#main.multi {
  section.mobile-breadcrumb {
    padding: 5px 10px 5px 10px;
    .content_breadcrumb {
      padding: 0px 0px 15px 0px;
      .checkout-progress {
        float: none;
        li {
          display: block;
          width: 33%;
          margin: 0px;
          text-align: center;
          background-color: #ebebeb;
          height: 20px;
          line-height: 62px;
          float: left;
          padding: 0;
        }
        li:first-of-type {
          border-radius: 20px 0px 0px 20px;
        }
        li:last-of-type {
          border-radius: 0px 20px 20px 0px;
        }
      }
    }
  }
}
$pages: (shipping, review, confirm);

@each $value in $pages {
  .#{$value} {
    #main.multi {
      section.mobile-breadcrumb {
        .content_breadcrumb {
          .checkout-progress li.#{$value} {
            background-color: #5b626a;
          }
        }
      }
    }
  }
}
$pages: (shipping, review, confirm);

@each $value in $pages {
  ##{$value} {
    #header {
      position: fixed;
      top: 0px;
      z-index: 9999;
    }
  }
}

#samples {
  #samples-panel {
    header {
      h2 {
        margin: 10px auto 12px auto;
      }
    }
  }
}

#viewcart,
#samples,
#shipping,
#confirm,
#review {
  .checkout-sticky-btndiv {
    margin-bottom: 0px !important;
    .checkout-sticky-button {
      background-color: #040a2b;
      width: 100%;
      font-size: 18px;
      margin-bottom: 0px !important;
      border: none;
      color: #ffffff;
    }
  }
}

#review {
  .review {
    padding-left: 0px;
  }
  .review-content-layout {
    padding-left: 10px;
  }
  .order-summary-panel {
    border-top: none;
  }
}

#review,
#confirm {
  .mobile-breadcrumb {
    ol.checkout-progress {
      margin-top: 0;
    }
  }
}

#viewcart,
#review {
  #main.multi {
    .offer_code {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #cccccc;
      .offer-code-panel__content {
        width: 100%;
        div.form_element {
          float: none;
          width: 100%;
          input {
            float: left;
            margin: 0 5px;
            width: 50%;
            height: 45px;
          }
          input.button {
            float: right;
            width: 38%;
          }
        }
      }
    }
    .left_col {
      .offer_code_box {
        margin-top: 10px;
        .offer-code__byline {
          position: relative;
          padding: 18px 6px;
          .title-icon {
            background-image: url('/media/images/global/sprites-s38a9885fce.png');
            background-repeat: no-repeat;
            background-size: auto auto;
            background-position: 0 -3269px;
            height: 53px;
            width: 53px;
            position: absolute;
            top: 3px;
            right: 14px;
          }
          &.open {
            .title-icon {
              background-position: 0 -2957px;
            }
          }
        }
      }
    }
  }
}

#viewcart,
#review {
  h2.checkout__panel--heading {
    display: none;
  }
}

#viewcart,
#review {
  .pg_wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .checkout__content {
    margin-left: 25px;
    margin-right: 25px;
  }
  h2.checkout-page-title {
    font-size: 36px;
  }
}

#shipping {
  .pg_wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .trans_detail_container {
    margin-left: 25px;
    margin-right: 25px;
  }
}

#viewcart,
#samples,
#shipping,
#review {
  footer {
    .page-sticky-footer {
      display: none;
    }
  }
}
// 2018 Creative refresh
.section-re-nutriv,
.brand-renutriv {
  &.device-mobile {
    .page-header {
      &:not(#{$alt}),
      &#{$sticky}:not(#{$alt}) {
        .header-inner .page-branding {
          .page-branding__logo {
            background-image: url('/media/boutiques/renutriv-landing/general/logo_estee-lauder_white.png');
            background-size: 166px 23px;
            width: 166px;
            height: 23px;
          }
        }
      }
    }
  }
  .page-header {
    &#{$search}#{$alt-color-white} {
      .page-branding {
        .page-branding__logo {
          background-image: url('/media/boutiques/renutriv-landing/general/logo_estee-lauder_white.png');
        }
      }
    }
  }
}

.zdc {
  &_popup {
    #cboxClose {
      background-image: $sprites-sprites;
    }
  }
}

.account-qr_section {
  display: flex;
  justify-content: center;
}

.device-mobile {
  &.page-product-promotions-setsgifts {
    &-advanced-night-repair-share-the-love-set {
      #colorbox {
        #cboxWrapper {
          #cboxContent {
            padding-top: 70px;
          }
        }
      }
    }
  }
  #colorbox {
    &.zdc_popup {
      height: 380px !important;
      margin-top: 15em;
    }
  }
}

.spp-product {
  &__image-container {
    .product-foundation-vto__btn {
      #{$ldirection}: 50px;
      bottom: 100px;
    }
  }
}

#signin {
  .checkout {
    .social-login {
      .fb-login-button {
        opacity: 1;
      }
    }
  }
  .social-login {
    .custom-fb {
      margin-top: -10px;
    }
    .custom-line {
      margin-top: 10px;
      padding: 0 25px;
    }
    &__container {
      h2 {
        display: none;
      }
    }
  }
}
