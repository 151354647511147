@if $password_strengthen {
  #signin {
    .sign-in-component {
      &__password-wrapper {
        &.password-field {
          overflow: visible;
        }
      }
    }
  }
  .password-field {
    &__info {
      #password_reset & {
        @media #{$medium-up} {
          top: -12px;
          width: 178px;
        }
      }
      #confirm & {
        @media #{$medium-up} {
          #{$rdirection}: -172px;
          top: -15px;
          width: 168px;
        }
      }
    }
    &__rules {
      padding: 10px;
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__fieldset {
        .form-item input {
          width: 100%;
        }
      }
      &__button {
        border: 1px solid $color-light-gray;
        border-color: $color-light-gray;
      }
      &__rules {
        padding: 0px;
        li {
          display: flex;
        }
      }
    }
    #cboxLoadedContent {
      height: auto !important;
    }
  }
  #registration {
    .profile-info__change-pwd-link {
      padding-top: 10px;
    }
    &.device-mobile {
      #colorbox {
        #cboxWrapper {
          #cboxContent {
            text-align: left;
          }
        }
        #cboxClose {
          right: 15px;
          top: 11px;
        }
        &.password-update-overlay {
          height: 580px !important;
          top: 200px !important;
          position: absolute !important;
        }
      }
    }
  }
}
