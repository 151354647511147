.section-store-locator.device-mobile .store-locator {
  ul.store-locator__nav li.store-locator__nav-item {
    border-right: none;
    width: 100%;
  }
  .local-search-form .store-name-filters.expanded {
    position: relative;
  }
  &.has-filters.expanded-filters .store-results {
    margin-top: 20px;
  }
}
