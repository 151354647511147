.contact-form {
  .address-form__registered-container label:first-child {
    display: block;
  }
  span {
    margin-left: 1px;
  }
}

.page-wrapper {
  .page-navigation {
    .level-3 {
      font-size: 18px;
    }
  }
}

.page-product {
  .spp-product__share {
    font-size: 15px;
  }
  .spp-product__details {
    h5 {
      font-size: 15px;
    }
  }
  .spp-product__description {
    .spp-product__quantity,
    .spp-product__add-button {
      font-size: 15px;
    }
  }
  .spp-product__personal {
    .spp-product__wishlist {
      font-size: 15px;
    }
  }
  .quickshop-inline__wishlist,
  .quickshop-inline__add-button {
    font-size: 15px;
  }
}

.mpp {
  .mpp__product-grid .quickshop-inline__phone-button {
    display: none;
  }
  .quickshop-inline__add-button,
  .quickshop-inline__wishlist {
    font-size: 15px;
  }
  .product__inventory-status,
  .quickshop-inline__price-size {
    text-align: center;
  }
  .product__inventory-status li span {
    display: block;
    padding-bottom: 10px;
  }
}

#waitlist-overlay {
  padding: 80px 0 20px 0;
}

#waitlist_thanks {
  padding: 140px 0;
}

.waitlist_error {
  width: 325px;
}

.quickshop-inline {
  .quickshop-inline__attribute-header,
  .quickshop-inline__attribute-text,
  .quickshop-inline__wishlist,
  .quickshop-inline__details {
    font-size: 15px;
  }
}

.page-footer {
  .footer-forms-location-sms-email-signup-elc-nodeblock {
    .footer-forms__header {
      font-size: 15px;
    }
  }
  .footer-signup-email {
    .form-submit {
      font-size: 15px;
    }
  }
}

.trust_mark_footer {
  margin-left: -25px;
  margin-right: -25px;
  border-top: 1px solid #a4a4ab;
  padding-top: 10px;
  padding-bottom: 10px;
}

.trust_mark_footer .trust_mark_footer__link {
  width: 128px;
  height: 145px;
  background-image: url('/media/images/global/lo2-white.png');
  background-repeat: no-repeat;
  text-indent: -9999px;
  display: block;
  margin: auto;
}

.promotions .mpp__product-grid {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.mpp__product-grid {
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  margin-top: 20px;
}

.ff-quiz__inner {
  .ff-quiz__coverage-image {
    font-size: 90px;
  }
  .ff-quiz__headline {
    font-size: 60px;
  }
  .ff-quiz__coverage-image--thumb {
    top: 26px;
  }
  .ff-quiz__slide--4 {
    .ff-quiz__slider {
      width: 90%;
    }
  }
}

.field-mobile-template {
  .mpp {
    h2.mpp {
      &__header {
        margin-top: 0px;
        padding-top: 0px;
      }
    }
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_radiobutton_wrapper {
      label {
        &::after,
        &::before {
          background: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}

.spp-product {
  .product-full {
    &__offer {
      background-color: $color-medium-gray-new;
      padding: 20px;
      margin-top: 30px;
    }
  }
}
